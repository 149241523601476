'use client'

import { ReactNode, useState } from 'react'
import { getQueryClient } from '@src/utils/react-query'
import { QueryClientProvider } from '@tanstack/react-query'

interface ReactQueryProviderProps {
  children: ReactNode
}

export default function TanstackProviders({ children }: ReactQueryProviderProps) {
  const [queryClient] = useState(() => getQueryClient())

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
