import { PlusIcon } from '@components/icons/common'
import { FlickIcon, HomeActiveIcon, HomeIcon, QuestActiveIcon, QuestIcon } from '@components/icons/home'
import { Routes } from '@src/config/routes.config'

export const MENU = [
  {
    id: 0,
    title: 'Home',
    icon: HomeIcon,
    activeIcon: HomeActiveIcon,
    route: Routes.home,
  },
  {
    id: 1,
    title: 'Quest',
    icon: QuestIcon,
    activeIcon: QuestActiveIcon,
    route: Routes.quest,
  },
  {
    id: 2,
    title: 'Add',
    icon: PlusIcon,
    activeIcon: PlusIcon,
    route: `${Routes.new}/post`,
  },
  {
    id: 3,
    title: 'Flicks',
    icon: FlickIcon,
    activeIcon: FlickIcon,
    route: Routes.flicks,
  },
]
