import { ReactNode, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'

import 'react-spring-bottom-sheet/dist/style.css'

function Container({ children }: { children: ReactNode }) {
  return <div className='w-full'>{children}</div>
}

function SocialModal({ open, onCloseModal }: { open?: boolean; onCloseModal?: () => void }) {
  return (
    <BottomSheet onDismiss={onCloseModal} open={Boolean(open)}>
      <Container>My awesome content here</Container>
    </BottomSheet>
  )
}

function WalletModal({ open, onCloseModal }: { open?: boolean; onCloseModal?: () => void }) {
  return (
    <BottomSheet onDismiss={onCloseModal} open={Boolean(open)}>
      <Container>Expand to full height</Container>
    </BottomSheet>
  )
}

function ButtonLogin({
  srcIcon = '/images/auth/login/icon-x.png',
  title = 'Sign in with Social Account',
  onPress,
}: {
  srcIcon?: string
  title?: string
  onPress?: () => void
}) {
  return (
    <button
      onClick={onPress}
      className='flex h-16 select-none items-center justify-center gap-2.5 rounded-full bg-w-soft-150 px-2 transition-transform active:scale-[0.99]'
    >
      <div className='relative size-12 overflow-hidden rounded-full'>
        <img src={srcIcon} alt='icon' className='h-full w-full' />
      </div>
      <span className='flex-1 text-left text-lg font-semibold leading-normal text-text-strong-950'>{title}</span>
    </button>
  )
}

function LoginPage() {
  const navigate = useNavigate()
  const [isOpenSocialModal, setIsOpenSocialModal] = useState(false)
  const [isOpenWalletModal, setIsOpenWalletModal] = useState(false)

  const handleOpenSocialModal = () => setIsOpenSocialModal(true)
  const handleCloseSocialModal = () => setIsOpenSocialModal(false)

  const handleOpenWalletModal = () => setIsOpenWalletModal(true)
  const handleCloseWalletModal = () => setIsOpenWalletModal(false)

  return (
    <>
      <div className='flex h-dvh w-dvw flex-col overflow-hidden bg-primary sm:max-w-[414px]'>
        <div className='relative -mx-4 h-20'>
          <img src='/images/auth/login/img-banner-login.png' alt='banner login' className='absolute inset-0' />
        </div>
        <img src='/images/auth/login/img-title-login.png' alt='title login' />
        <div className='relative flex flex-1 flex-col justify-end overflow-hidden'>
          <img
            src='/images/auth/login/img-bg-content-login.svg'
            alt='bg content login'
            className='absolute inset-0 h-full w-full'
          />

          <div className='relative flex flex-col gap-5'>
            <div className='px-5 py-3'>
              <img src='/images/auth/login/img-title-content-login.svg' alt='title content login' />
            </div>

            <div className='flex flex-col gap-3 px-5'>
              <ButtonLogin onPress={handleOpenSocialModal} srcIcon='/images/auth/login/icon-x.png' />
              <ButtonLogin
                onPress={handleOpenWalletModal}
                srcIcon='/images/auth/login/icon-wallet.png'
                title='Sign in with Wallet'
              />
              <ButtonLogin
                onPress={() => navigate('/auth/login/email')}
                srcIcon='/images/auth/login/icon-email.png'
                title='Sign in with Email'
              />
            </div>

            <div className='flex justify-center gap-2 text-base leading-normal tracking-tight'>
              <span className='font-normal text-[#666666]'>Don’t have an account?</span>
              <Link to={'/auth/register'}>
                <span className='font-semibold text-[#ff723b]'>Sign up</span>
              </Link>
            </div>

            <div className='h-[21px]'></div>
          </div>
        </div>
      </div>

      <SocialModal open={isOpenSocialModal} onCloseModal={handleCloseSocialModal} />
      <WalletModal open={isOpenWalletModal} onCloseModal={handleCloseWalletModal} />
    </>
  )
}

export default LoginPage
