import { Outlet } from 'react-router-dom'
import MyToaster from '@src/components/toaster'

export default function MainLayout() {
  return (
    <div className='relative h-[100dvh]'>
      <Outlet />
      <MyToaster />
    </div>
  )
}
