import axios from 'axios'
import { parse } from 'cookie'

import authService from './auth'

const BASE_URL = import.meta.env.VITE_PUBLIC_API_ENDPOINT

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const accessToken = parse(document.cookie).accessToken

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (error.response && ![403, 401].includes(error.response.status)) {
      const { data } = error.response
      throw data
    }

    await authService
      .refreshToken()
      .then((res) => {
        console.log('Log - res: ', res)
        return instance(originalRequest)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
)

export default instance
