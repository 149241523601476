import { createBrowserRouter, createRoutesFromElements, redirect, Route, RouterProvider } from 'react-router-dom'
import { Routes as MyRoutes } from '@src/config/routes.config'
import MainLayout from '@src/layout/main-layout'
import NavLayout from '@src/layout/nav-layout'
import LoginPage from '@src/pages/auth/login'
import EmailLoginPage from '@src/pages/auth/login/email'
import OnBoardingPage from '@src/pages/onboarding'
// @ts-ignore
import ChatPage from 'chatApp/ChatPage'
// @ts-ignore
import { parse } from 'cookie'
// @ts-ignore
import FlickPage from 'postsApp/FlickPage'
// @ts-ignore
import HomePage from 'postsApp/HomePage'
// @ts-ignore
import NotificationPage from 'postsApp/NotificationPage'
// @ts-ignore
import SearchPage from 'postsApp/SearchPage'
// @ts-ignore
import ProfilePage from 'profileApp/ProfilePage'
// @ts-ignore
import QuestPage from 'questApp/QuestPage'

import Protected from './protected'

const isHasToken = () => {
  const token = parse(document.cookie).token
  if (token) {
    return redirect('/')
  }
  return null
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<MainLayout />}>
      {/* Private Route */}
      <Route element={<Protected />}>
        <Route path={MyRoutes.flicks} element={<FlickPage />} />
        <Route path={MyRoutes.search} element={<SearchPage />} />
        <Route path={MyRoutes.notification} element={<NotificationPage />} />
        <Route path={MyRoutes.chat} element={<ChatPage />} />
        <Route element={<NavLayout />}>
          <Route path={MyRoutes.profile} element={<ProfilePage />} />
          <Route path={MyRoutes.quest} element={<QuestPage />} />
          <Route index element={<HomePage />} />
        </Route>
      </Route>

      {/* Public Route */}
      <Route loader={isHasToken} path={MyRoutes.onboarding} element={<OnBoardingPage />} />
      <Route loader={isHasToken} path={MyRoutes.authLogin} element={<LoginPage />} />
      <Route loader={isHasToken} path={MyRoutes.authLoginEmail} element={<EmailLoginPage />} />
    </Route>
  ),
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      v7_partialHydration: true,
      v7_fetcherPersist: true,
    },
  }
)

export default function AppRouter() {
  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  )
}
