import axios from 'axios'

import instance from '../instance.axios'

const authService = {
  postLogin: (body: { email: string; password: string }) => instance.post('/auth/sign-in', body),

  getUser: () => instance.get('/users'),
  getUsers: (id: string) => instance.get('/users/' + id),
  refreshToken: () => axios.post('/api/auth/refresh-token'),
}

export default authService
