import { ToastBar, Toaster } from 'react-hot-toast'

export default function MyToaster() {
  return (
    <Toaster
      gutter={16}
      position='top-center'
      toastOptions={{
        duration: 3000,
        className: '!max-w-[414px]',
      }}
    >
      {(t) => (
        <ToastBar
          style={{
            background: 'unset',
            boxShadow: 'unset',
            width: '100%',
            padding: 'unset',
            maxWidth: 'calc(100% - 24px)',
          }}
          toast={t}
        >
          {({ message }) => message}
        </ToastBar>
      )}
    </Toaster>
  )
}
