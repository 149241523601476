import { useLocalStorage } from '@uidotdev/usehooks'

import TanstackProviders from './providers/tanstack.provider'
import AppRouter from './routes'

import './App.css'

function App() {
  const [theme] = useLocalStorage('theme', 'orange')
  return (
    <main id='main' data-theme={theme}>
      <TanstackProviders>
        <AppRouter />
      </TanstackProviders>
    </main>
  )
}

export default App
