import Marquee from 'react-fast-marquee'
import { Link } from 'react-router-dom'
import { EffectCreative, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css/pagination'
import 'swiper/css/effect-creative'

import 'swiper/css'
import styles from './onboarding.module.css'

function NavigateButton() {
  return (
    <Link to={'/auth/login'} className='mx-5 mb-5 flex items-center justify-between rounded-2xl bg-white p-5'>
      <span className='text-lg font-semibold leading-normal text-text-black'>Get started</span>
      <img src='/icons/icon-arrow-up-right.svg' alt='icon arrow up right' />
    </Link>
  )
}

function FirstSlide() {
  return (
    <div className='relative h-full w-full'>
      <div className='absolute inset-x-0 top-1/2 -translate-y-1/2 bg-white'>
        <Marquee autoFill>
          <img src='/icons/icon-logo.svg' alt='icon logo' className='mx-1' />
          <span className='mx-1 text-3xl font-semibold leading-10 text-text-black'>SOCIALFI</span>
        </Marquee>
      </div>
      <img
        src='/images/onboarding/img-onboarding.png'
        alt='content'
        className='relative h-full w-full object-contain'
      />
    </div>
  )
}

function HeaderTitle() {
  return (
    <div className='relative px-4 py-5'>
      <h1
        style={{
          background:
            'radial-gradient(54.8% 130.51% at 62.15% 11.46%, #FFF 0%, rgba(255, 255, 255, 0.80) 50.9%, rgba(255, 255, 255, 0.45) 100%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        className='text-[32px] font-medium leading-[40px]'
      >
        Support your favorite creators and enjoy a unique, fan-focused experience!
      </h1>
    </div>
  )
}

function SecondSlide() {
  return (
    <div className='relative flex h-full w-full flex-col overflow-hidden'>
      <HeaderTitle />

      <div className='relative flex max-h-full items-center justify-center overflow-hidden'>
        <div className='relative -mx-[120px]'>
          <img src='/images/onboarding/img-content-slide-2.png' alt='content' />
        </div>
        <div className='absolute flex h-[252px] max-h-full w-[168px] flex-col rounded-xl bg-white'>
          <div className='relative flex-1 overflow-hidden p-[3px]'>
            <div className='relative h-full w-full overflow-hidden rounded-tl-xl rounded-tr-xl'>
              <video
                src='/images/onboarding/video-second-slide.mp4'
                autoPlay
                loop
                muted
                playsInline
                className='h-full w-full object-cover'
              />
            </div>
          </div>
          <div className='p-[7px] text-xs font-medium leading-[14px] text-[#1b1b1b]'>
            Engage with top creators and access a special, community-focused experience 👀
          </div>
        </div>
      </div>
    </div>
  )
}

function ThirdSlide() {
  return (
    <div className='flex h-full w-full flex-col'>
      <HeaderTitle />

      <Marquee></Marquee>

      <div className='relative flex flex-1 items-center justify-center overflow-hidden'>
        <div className='absolute rotate-[36deg]'>
          <Marquee autoFill>
            <div className='flex gap-1.5 border-b border-t border-[#CCF677] bg-state-highlighted-base px-0.5'>
              <div className='text-xs font-medium uppercase leading-3 text-white'>
                Unlock the Future of the Decentralized APP
              </div>
              <svg width='35' height='10' viewBox='0 0 35 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M0.404785 0.531738L5.0888 0.531738V0.531976L30.0701 0.531976V9.90002L5.08866 9.90002L5.08866 5.21576L0.404785 5.21576L0.404785 0.531738ZM34.7542 5.21598L30.0701 5.21598V9.9L34.7542 9.9V5.21598Z'
                  fill='white'
                />
              </svg>
            </div>
          </Marquee>
        </div>

        <div className='flex h-[400px] w-[320px] items-center justify-center'>
          <div className='absolute -translate-y-1/2'>
            <img src='/images/onboarding/img-block-1.svg' alt='content' className='animate-move-right' />
          </div>
          <div className='absolute'>
            <img src='/images/onboarding/img-block-2.svg' alt='content' className='animate-move-right-1' />
          </div>
          <div className='absolute translate-y-1/2'>
            <img src='/images/onboarding/img-block-3.svg' alt='content' className='animate-move-right-2' />
          </div>
        </div>

        <div className='absolute -rotate-[36deg]'>
          <Marquee autoFill>
            <div className='flex gap-1.5 border-b border-t border-[#CCF677] bg-white px-0.5'>
              <div className='text-xs font-medium uppercase leading-3 text-primary'>
                Unlock the Future of the Decentralized APP
              </div>
              <svg width='35' height='10' viewBox='0 0 35 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M0.404785 0.531738L5.0888 0.531738V0.531976L30.0701 0.531976V9.90002L5.08866 9.90002L5.08866 5.21576L0.404785 5.21576L0.404785 0.531738ZM34.7542 5.21598L30.0701 5.21598V9.9L34.7542 9.9V5.21598Z'
                  fill='#FF723B'
                />
              </svg>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  )
}

function HeroBanner() {
  return (
    <div className='relative flex-1 overflow-hidden'>
      <Swiper
        grabCursor={true}
        effect={'creative'}
        pagination={{
          clickable: true,
          horizontalClass: styles.horizontal,
          bulletActiveClass: styles.bulletActive,
          bulletClass: styles.bullet,
        }}
        creativeEffect={{
          prev: {
            opacity: 0,
            shadow: true,
            translate: ['-20%', 0, 0],
          },
          next: {
            opacity: 0,
            translate: ['100%', 0, 0],
          },
        }}
        className='relative h-full w-full'
        modules={[Pagination, EffectCreative]}
      >
        <SwiperSlide className='bg-primary pb-[60px] pt-[21px]'>
          <FirstSlide />
        </SwiperSlide>
        <SwiperSlide className='bg-primary pb-[60px] pt-[21px]'>
          <SecondSlide />
        </SwiperSlide>
        <SwiperSlide className='bg-primary pb-[60px] pt-[21px]'>
          <ThirdSlide />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

function OnBoardingPage() {
  return (
    <div className='relative flex h-dvh w-dvw flex-col gap-[60px] bg-primary sm:max-w-[414px]'>
      <HeroBanner></HeroBanner>
      <NavigateButton></NavigateButton>
    </div>
  )
}

export default OnBoardingPage
