import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import authService from '@src/services/auth'
import { useLocalStorage } from '@uidotdev/usehooks'
import { isAxiosError } from 'axios'
import { serialize } from 'cookie'

type FromState = {
  email: string
  password: string
}
function EmailLoginPage() {
  const navigate = useNavigate()
  const [, setIsFirstTime] = useLocalStorage('isFirstTime', true)
  const { register, handleSubmit } = useForm<FromState>()

  const onSubmit = async (data: FromState) => {
    await authService
      .postLogin(data)
      .then((res) => {
        document.cookie = serialize('accessToken', res.data.data.accessToken, { maxAge: 60 * 60 * 24 * 365 })
        document.cookie = serialize('refreshToken', res.data.data.accessToken, { maxAge: 60 * 60 * 24 * 365 })
        setIsFirstTime(false)
        navigate('/')
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          toast.error(err.response?.data.message)
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2 p-4'>
      <label htmlFor='email'></label>
      <input type='email' id='email' {...register('email')} placeholder='email' />

      <label htmlFor='password'></label>
      <input type='password' id='password' {...register('password')} placeholder='password' />

      <button type='submit'>Submit</button>
    </form>
  )
}

export default EmailLoginPage
