import { Fragment } from 'react/jsx-runtime'
import { Outlet } from 'react-router-dom'

import BottomNav from './bottom-nav'

export default function NavLayout() {
  return (
    <Fragment>
      <Outlet />
      <BottomNav />
    </Fragment>
  )
}
