import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Routes } from '@src/config/routes.config'
import { MENU } from '@src/constants/menu.constant'
import { commonDataStore } from '@src/store/common-data-store'

import UserAvatar from '/images/random-avatar/random-avatar-1.png'

export default function BottomNav() {
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const { isVisible, setIsVisible } = commonDataStore()
  const location = useLocation()

  const handleScroll = (e: any) => {
    const scrollTop = e.target?.scrollTop || 0
    if (scrollTop > lastScrollTop) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop)
  }

  useEffect(() => {
    document.getElementById('root')?.addEventListener('scroll', handleScroll)
    return () => {
      document.getElementById('root')?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollTop])

  return (
    <div
      className={`fixed bottom-0 left-1/2 z-[100] flex w-full -translate-x-1/2 items-center justify-center pb-6 transition-transform duration-300 sm:max-w-max-width ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}
    >
      <div className='absolute inset-0 blur' />
      <div
        style={{ boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.06)' }}
        className='relative flex max-w-[320px] items-center gap-2 rounded-full bg-b-weak-50 p-1'
      >
        {MENU.map((item) => (
          <Link
            key={item.id}
            to={item.route}
            className={`p-4 ${item.id === 2 && 'rounded-full bg-primary-base text-white'}`}
          >
            {item.route === location.pathname && item.id !== 2 ? <item.activeIcon /> : <item.icon />}
          </Link>
        ))}

        <Link to={Routes.profile} className='p-2'>
          <img
            src={UserAvatar}
            width={40}
            height={40}
            alt='user avatar'
            className='aspect-square h-10 w-10 rounded-full object-cover'
          />
        </Link>
      </div>
    </div>
  )
}
