import { Navigate, Outlet } from 'react-router-dom'
import { useLocalStorage } from '@uidotdev/usehooks'
import { parse } from 'cookie'

const Protected = () => {
  const [isFirstTime] = useLocalStorage('isFirstTime', true)
  const token = parse(document.cookie).accessToken

  return token ? <Outlet /> : <Navigate to={isFirstTime ? '/onboarding' : '/auth/login'} />
}

export default Protected
