export const Routes = {
  home: '/',
  flicks: 'flicks',
  profile: 'profile',
  quest: 'quest',
  search: 'search',
  new: 'new',
  notification: 'notification',
  chat: 'chat',
  onboarding: 'onboarding',
  authLogin: 'auth/login',
  authLoginEmail: 'auth/login/email',
}
